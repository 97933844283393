import React from "react";
import ArticleCard from "../../card/articleCard/articleCard";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation]);

import "swiper/css";
import "swiper/css/navigation";

import "./articlesCarousel.scss";

import articleImg from "../../../images/articles/photo-article-default.webp";

export default function ArticlesCarousel({ articles, products, showDate = true }) {
  const content = articles ? articles : products;

  let type = ""
  if (articles && articles.length > 0) {
    type = "article";
  }

  if (products && products.length > 0) {
    type = "produit";
  }

  return (
    <div className="article-carousel">
      {content.length < 3 ? (
        <div className="article-carousel__slide article-carousel__slide--no">
          {content.map((article, i) => {
            return (
              <div key={i} >
                <ArticleCard
                  size="small"
                  title={article.Titre}
                  date={article.Date_De_Publication}
                  slug={article.Slug}
                  type={type}
                  btnText={type === "produit" ? "Découvrir" : "Lire l’article"}
                  img={
                    article.Image_Vignette
                      ? article.Image_Vignette.localFile
                      : articleImg
                  }
                  showDate={showDate}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={50}
          centeredSlides={false}
          roundLengths={true}
          loopAdditionalSlides={30}
          navigation={true}
          loop={true}
          breakpoints={{
            1650: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
          }}
        >
          {content.map((article, index) => (
            <SwiperSlide key={index}>
                <ArticleCard
                  size="square"
                  title={article.Titre}
                  date={article.Date_De_Publication}
                  slug={article.Slug}
                  type={type}
                  btnText={type === "produit" ? "Découvrir" : "Lire l’article"}
                  img={
                    article.Image_Vignette
                      ? article.Image_Vignette.localFile
                      : articleImg
                  }
                  showDate={showDate}
                />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
