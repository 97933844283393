import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import Content from "../../components/content/content";
import HeaderArticle from "../../components/header/headerArticle/headerArticle";
import SeeMore from "../../components/seeMore/seeMore";

import "./style.scss";

import defaultImgArticle from "../../images/articles/default-img-article-page.webp";

const ArticlePage = ({location, data}) => {
  const article = data.strapiArticle;
  return (
    <>
      <Helmet>
        <body className="article"/>
      </Helmet>

      <Layout isProgressBar={true}>
        <SEO title={article.Titre} description={article.Description} article={article} />
        <HeaderArticle
          title={article.Titre}
          text={article.Description}
          date={article.Date_De_Publication}
          imagePath={
            article.Image_De_Couverture
              ? article.Image_De_Couverture.localFile.publicURL
              : defaultImgArticle
          }
          location={location}
          crumbLabel={article.Titre}
        />
        {article.Contenu && <Content content={article.Contenu} location={location}/>}
        {article.Articles.length > 0 && <SeeMore articles={article.Articles} btnHref="/articles"/>}
      </Layout>
    </>
  );
};

export const query = graphql`
  query articlePageQuery($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      Slug
      Titre
      Description
      Contenu {
        __typename
        ... on STRAPI__COMPONENT_BLOCS_BLOC {
          id
          Fond_De_Couleur
          Couleur_de_fond
          Position_De_L_Image
          Titre
          strapi_component
          Contenu {
            data {
              Contenu
            }
          }
          Images {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }          
          Bouton
          Lien
        }
        ... on STRAPI__COMPONENT_BLOCS_BOUTON {
          id
          Texte_str: Texte
          Url
          strapi_component
          Document {
            localFile {
                publicURL
                name
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_CAS_PRATIQUE {
          id
          Citation {
            data {
              Citation
            }
          }
          Texte_Bouton
          Texte_Court {
            data {
              Texte_Court
            }
          }
          Texte_Long {
            data {
              Texte_Long
            }
          }
          Titre
          Url_Bouton
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_CITATION {
          id
          Texte {
            data {
              Texte
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_DOUBLE_IMAGES {
          id
          strapi_component
          Image1 {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          Image2 {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_IMAGE_CENTRE {
          id
          full_width
          Image {
            localFile {
              publicURL
            }
          }
          strapi_id
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_IMAGE {
          id
          Image {
            localFile {
              publicURL
            }
          }
          Lien_Bouton
          Texte_str: Texte
          Texte_Bouton
          Document {
            localFile {
                publicURL
            }
          }
          Titre
          Fil_ariane
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_FAQ {
          id
          Question
          Reponse {
            data {
                Reponse
            }
          }
          strapi_id
          strapi_component
        }
        ... on STRAPI__COMPONENT_BLOCS_SPACER {
          id
          strapi_id
          strapi_component
          Couleur
          Espace
        }
        ... on STRAPI__COMPONENT_BLOCS_TITRE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Titre
        }
        ... on STRAPI__COMPONENT_BLOCS_ACTION {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Actions {
            id
            Titre
            Description {
                data {
                    Description
                }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_SERVICE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Services {
            id
            Titre
            Bouton
            Lien
            Categorie
            Description {
              data {
                Description
              }
            }
            Image {
              localFile {
                publicURL
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_PARTENAIRE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Partenaires {
            id
            Nom
            Description
            Logo {
              localFile {
                publicURL
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_TEXTE {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Texte {
            data {
              Texte
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCS_CAROUSEL {
          id
          strapi_id
          strapi_component
          Couleur_de_fond
          Titre
          Images {
            localFile {
              publicURL
            }
          }
        }
      }
      Date_De_Publication(formatString: "DD/MM/YYYY")
      Image_De_Couverture {
        localFile {
          publicURL
        }
      }
      Image_Vignette {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
          url
          publicURL
        }
      }
      Articles {
        Titre
        Slug
        Date_De_Publication
        Image_Vignette {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`;

export default ArticlePage;
