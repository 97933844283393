import React from "react";
import Button from "../button/button";

import ArticlesCarousel from "../carousel/articlesCarousel/articlesCarousel";

import "./seeMore.scss";

export default function SeeMore({
  title = "À lire aussi",
  btnText = "Tous nos articles",
  btnHref,
  articles,
  products,
  showDate = true
}) {
  return (
    <section className="section-container seeMore">
      <h2 className="seeMore__heading">{title}</h2>
      <div className="seeMore__btn-container">
        <Button
          text={btnText}
          className="seeMore__btn"
          onClick={() => (window.location.href = btnHref)}
        />
      </div>
      <ArticlesCarousel articles={articles} products={products} showDate={showDate} />
    </section>
  );
}
