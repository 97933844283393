import React from "react";
import ScrollButton from "../../button/scrollButton/scrollButton";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import CustomBreadcrumb from "../../customBreadcrumb/customBreadcrumb";

import logoLinkedin from "../../../images/articles/logo-linkedin.png";
import "./headerArticle.scss";

export default function HeaderArticle({
  imagePath,
  title,
  text,
  secondText,
  date,
  location,
  crumbLabel,
}) {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: crumbLabel,
  });
  return (
    <header className="header-article" id="header"> 
      <div className="header-article__bloc--2">
        <div className="header-article__content">
          <CustomBreadcrumb crumbs={crumbs} />
          <h1>{title}</h1>
          <p>{text}</p>
          {secondText && <p>{secondText}</p>}
          {date && <time className="header-article__date">{date}</time>}
        </div>
      </div>
      <div className="header-article__bg"  style={{ backgroundImage: `url(${imagePath})` }}></div>
      <ScrollButton headerElemId='header' className="header-article__btn" />
    </header>
  );
}
